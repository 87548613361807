<template>
  <div style="margin-top: -10px; z-index: 1">

    <div class="user-portfolio-body" v-if="!loader">
      <div class="user-portfolio-info">
        <div class="info-section-1">
          <img class="info-username-img" :alt="username" :src="userInfo.avatar_url"/>
          <h1 class="info-username-text"><p>{{username}}</p>kullanıcısının portfolyosu </h1>
        </div>

        <div @click="clickedShareButton"  class="share-area">
          <social-sharing :url="url"  inline-template>
            <div class="user-socialmedia-icons">
              <network network="facebook">
                <img style="cursor: pointer; margin-left: 20px;" src="https://gcdn.bionluk.com/site/cicons/ic-facebook.svg" onload="SVGInject(this)" class="facebook-share-icon"/>
              </network>
              <network network="twitter">
                <img   style="cursor: pointer; margin-left: 20px;" src="https://gcdn.bionluk.com/site/cicons/ic-twitter.svg" onload="SVGInject(this)" class="twitter-share-icon"/>
              </network>
              <network  network="linkedin">
                <img style="cursor: pointer; margin-left: 20px;" src="https://gcdn.bionluk.com/site/cicons/ic-linkedin.svg" onload="SVGInject(this)" class="linkedin-share-icon"/>
              </network>
            </div>
          </social-sharing>
          <div @click="copyToClipboard()">
            <img  style="cursor: pointer;margin-left: 20px;" src="https://gcdn.bionluk.com/site/cicons/ic-link.svg" onload="SVGInject(this)" class="link-share-icon"/>
          </div>
        </div>

       <div style=" text-align: center; display: flex; flex-direction: column; align-items: center">
         <p class="mid-text">{{userInfo.username}} kullanıcısının işlerini beğendiysen, iş ilanlarını incelemeni tavsiye ederiz. Belki de ihtiyacın olan o işi birlikte yaparsınız.😉</p>
         <router-link :to="'/'+userInfo.username" class="profile-link">[ilanlarına göz at]</router-link>
       </div>


      </div>
      <div class="user-portfolio-main">
        <div class="portfolio-array">
          <div class="user-portfolio" v-for="portfolio in portfolios">
            <div class="head-section">
              <p class="title">
                <router-link :to="specifyPostUrl(portfolio.uuid)">
                  {{portfolio.name}}
                </router-link>
               </p>
              <p class="sub-title" v-if="portfolio.rating_user.id"><router-link :to="'/'+portfolio.rating_user.username">{{portfolio.rating_user.username}}</router-link> için yapıldı.</p>
              <p v-else class="sub-title">{{portfolio.category_sub_name}}</p>
            </div>
            <router-link :to="specifyPostUrl(portfolio.uuid)">
              <div>
                <div style="position: relative;">
                  <div v-if="portfolio.file_type === 'audio'" class="sound-icon-container">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="sound-icon">
                  </div>
                  <div v-if="portfolio.file_type === 'video'" class="sound-icon-container">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="sound-icon">
                  </div>
                  <img class="user-portfolio-image" :src="portfolio.image_url_small" :alt="portfolio.name"/>
                </div>
                <div class="foot-section">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="like-icon"/>
                  <span>{{portfolio.fav_count}}</span>
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-messages.svg" onload="SVGInject(this)" class="like-icon"/>
                  <span>{{portfolio.comment_count}}</span>
                </div>
              </div>
            </router-link>
          </div>

        </div>
        <div class="see-more-div" @click="retrievePortfolios(offset, limit, true)"
             v-if="!seemoreLoader && seemorediv">
          <p>Daha fazla göster +</p>
        </div>
        <loader class="see-more-div-loader" v-if="seemoreLoader"></loader>

      </div>
    </div>
    <div class="user-portfolio-body" style="height: 100vh" v-else>
      <loader></loader>
    </div>
  </div>

</template>


<script>

  export default {
    name: "src-pages-body-portfolio-v1_mobile",
    data() {
      return {
        username: null,
        offset: 0,
        limit: 8,
        seemorediv: false,
        loader: true,
        seemoreLoader: false,
        portfolios: [],
        userInfo: {
          avatar_url:'',
          username:''
        },
        url: window.location.href,
      }
    },

    methods: {

      copyToClipboard(){
        const el = document.createElement('textarea');
        el.value = 'https://bionluk.com/portfolyo/'+this.userInfo.username;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.$toasted.global.infoToast({description: 'Portfolyo linkin kopyalandı.'});
      },

      clickedShareButton(){
        this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'portfolio share buttons'});
      },



	    retrievePortfolios(offset, limit, comingSeeMoreButton) {
        if (comingSeeMoreButton) {
          this.seemoreLoader = true;
        }
        this.$store.state.indexHTMLTitle = this.username + ' portfolyo sayfası | Bionluk';
        this.$store.state.indexHTMLMetaDescription = this.username + ' kullanıcına ait iş örnekleri';
        this.api.seller.listPortfolios(this.username, this.Constants.PORTFOLIO_STATUSES.ACTIVE, limit, offset)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.userInfo = result.data.portfolio_user;
              this.Helper.trackEvents.pageView(this.Helper.portfolioOpennedProps(result.data.portfolio_user.userName, result.data.portfolio_user.userID, result.data.portfolio_user.userUUID));
              this.seemorediv = result.data.seemorediv;

              this.portfolios = this.portfolios.concat(result.data.portfolios);
              this.offset += limit;
              this.seemorediv = result.data.seemorediv;
              if(this.portfolios.length > 0)  this.userInfo =  result.data.portfolios[0].user;
            } else {
	            this.$store.commit(this.types.SET_COMPONENT_MAP, this.$store.state.routerMap["/*"])

            }
            this.loader = false;
            this.seemoreLoader = false;
          })
          .catch(err => {
            this.seemoreLoader = false;
            this.loader = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },

      specifyPostUrl(ID) {
        return /freelancer-vitrin/ + ID;
      },

      preventClicking(event) {
        event.returnValue = false;
        return false;
      },

      showPortfolioDetailModal(portfolio) {
        // this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: portfolio.uuid}});
      },
    },

    watch: {
      "$store.state.routerParams": function (newValue, oldValue) {
        this.username = newValue[0];
        this.retrievePortfolios(this.offset, this.limit);
      }
    },

    created() {
      this.username = this.$store.state.routerParams[0];
      this.retrievePortfolios(this.offset, this.limit);
    }

  }

</script>

<style scoped lang="scss">


  .foot-section{

    display: flex;
    align-items: center;
    margin-left: 20px;
    height: 40px;


    font-size: 16px;
    font-weight: 600;
    color: #8b95a1;

    span{
      padding-left: 5px;
      padding-right: 15px;

    }
    .like-icon /deep/ {
      cursor: pointer;
      width: 16px;
      height: 16px;
      path {
        fill: #bfc8d2!important;
      }
    }

  }

  .share-area{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .user-socialmedia-icons {
      display: flex;
      flex-direction: row;
      height: 26px;
    }
  }



  .mid-text{

    font-size: 14px;
    font-weight: normal;
    width: 90%;

    line-height: 1.57;
    color: #6a7685;
    margin-bottom: 15px;
  }

  .profile-link{
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    color: #fd4056;
  }

  .user-portfolio-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    padding-bottom: 100px;
    background-color: #f4f5f7;
  }


  .info-section-1 {
    width: 100vw;
    border-bottom: solid 1px #eaedf2;
    display: flex;
    flex-direction: column;
    align-items: center;

    .info-username-img {
      width: 88px;
      height: 88px;
      border-radius: 50%;
      border:2px solid #fd4056;
      margin-top: 20px;
    }

    .info-username-text {
      text-align: center;
      font-weight: 600;
      color: #2d3640;
      font-size: 22px;
      margin: 0;

      p {
        font-weight: normal;
        color: #6a7685;
        margin-top: 12px;
        margin-bottom: 2px;

      }
    }


  }




  .user-portfolio-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    margin-top: 30px;
    .portfolio-array {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

    }

    .head-section{
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title{
        margin-top: 15px;
        margin-bottom: 3px;
        margin-left: 20px;
        font-size: 16px;
        font-weight: 600;
        color: #2d3640;
      }
      .sub-title{
        margin-bottom: 15px;
        margin-left: 20px;
        font-size: 14px;
        font-weight: 300;
        color: #5e6b79;
        a{
          font-size: 14px;
          font-weight: 600;
          color: #fa4a5e;
        }
      }
    }


  }

  .user-portfolio {

    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }

    .user-portfolio-image {
      max-width: 370px;
      width: calc(100vw - 40px);
    }

  }



  .see-more-div {
    margin-top: 30px;
    cursor: pointer;
  }

  .see-more-div-loader {
    width: 25px;
    height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .facebook-share-icon /deep/ {
    cursor: pointer;
    width: 26px;
    height: 26px;
    path {
      fill: #3b5998!important;
      &:hover{
        opacity: 0.8;
      }
    }
  }

  .twitter-share-icon /deep/ {
    width: 26px;
    height: 26px;
    cursor: pointer;
    path {
      fill: #1da1f2;
      &:hover{
        opacity: 0.8;
      }
    }
  }

  .linkedin-share-icon /deep/ {
    width: 26px;
    height: 26px;
    cursor: pointer;
    path {
      fill: #0077b5;
      &:hover{
        opacity: 0.8;
      }
    }
  }

  .link-share-icon /deep/ {
    cursor: pointer;
    width: 26px;
    height: 26px;
    path {
      fill: #f2b46d;
      &:hover{
        opacity: 0.8;
      }
    }
  }
</style>
